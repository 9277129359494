import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import './ProfilePage.css'
import { useTelegram } from '../../hooks/useTelegram'
import SVGSpinner from '../Spinner/SVGSpinner'
import CopyIcon from '../../icons/CopyIcon'
import LinkIcon from '../../icons/LinkIcon'
import CheckIcon from '../../icons/CheckIcon'
import TicketIcon2 from '../../icons/TicketIcon2'
import SendPromoIcon from '../../icons/SendPromoIcon'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppProvider'
import InfoIcon from '../../icons/InfoIcon'

const ProfilePage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { role } = useContext(AppContext)
  const [totalSalary, setTotalSalary] = useState(0)
  console.log(process.env.PUBLIC_URL)
  const { user, tg } = useTelegram()
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [balance, setBalance] = useState(0)
  const [code, setCode] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [isPromoCodeFound, setIsPromoCodeFound] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('transparent')
  const navigate = useNavigate()

  const [promoCodeResponse, setPromoCodeResponse] = useState('')

  const [referralLink, setReferralLink] = useState('')

  tg.MainButton.hide()

  const rootRef = useRef(null)

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    updateViewportHeight()

    window.addEventListener('resize', updateViewportHeight)

    const handleFocus = () => {
      if (rootRef.current) {
        rootRef.current.style.height = `calc(var(--vh, 1vh) * 100)`
      }

      const handleScroll = () => {
        if (rootRef.current) {
          rootRef.current.scrollTop = window.scrollY
          window.scrollTo(0, 0)
        }
      }

      window.addEventListener('scroll', handleScroll, true)

      return () => {
        window.removeEventListener('scroll', handleScroll, true)
      }
    }

    document.addEventListener('focusin', handleFocus)
    document.addEventListener('focusout', handleFocus)

    return () => {
      window.removeEventListener('resize', updateViewportHeight)
      document.removeEventListener('focusin', handleFocus)
      document.removeEventListener('focusout', handleFocus)
    }
  }, [])

  useEffect(() => {
    if (user?.id && role === 'social-media') {
      fetch(`${process.env.REACT_APP_API_URL}/get-total-salary/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTotalSalary(data.totalSalary)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [user])

  useEffect(() => {
    if (user?.id) {
      fetch(`${process.env.REACT_APP_API_URL}/get-referral-link/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setReferralLink(data.referralLink)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [user])

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink)
  }

  useEffect(() => {
    if (user?.id) {
      fetch(`${process.env.REACT_APP_API_URL}/get-user-balance/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBalance(data.balance)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [user])

  useEffect(() => {
    if (user?.id) {
      console.log(user?.id)
      fetch(`${process.env.REACT_APP_API_URL}/get-user-avatar/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAvatarUrl(data.avatarUrl)
          setLoading(false) // Загрузка завершена
        })
        .catch((error) => {
          console.error('Error:', error)
          setLoading(false) // Загрузка завершена даже в случае ошибки
        })
    } else {
      setLoading(false) // Если нет user.id, загрузка также считается завершенной
    }
  }, [user])

  const handleCodeChange = async (event) => {
    setCode(event.target.value.toUpperCase())
  }

  const handleAdminClick = async () => {
    navigate('/admin')
  }

  const handleApplyClick = async () => {
    if (code.trim() === '') {
      // alert('Поле ввода пустое. Введите промо-код.');
      return
    }

    setIsSubmitting(true) // Начинаем отправку запроса

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/apply-promo-code`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'any-value',
        },
        body: JSON.stringify({
          promoCode: code,
          userId: user.id.toString(),
        }),
      }
    )

    const data = await response.json()

    if (data.success) {
      setBalance(data.newBalance) // предполагается, что у вас есть функция setBalance для обновления баланса
      setPromoCodeResponse(data.message)
      alert(data.message)
    } else {
      setPromoCodeResponse(data.message)
      alert(data.message)
    }
    setCode('')
    setIsSubmitting(false) // Закончили отправку запроса
  }

  if (loading) {
    return <SVGSpinner /> // Или любой другой индикатор загрузки
  }

  const handleButtonClick = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        setButtonDisabled(true)
        setTimeout(() => {
          setButtonDisabled(false)
        }, 2000)
      })
      .catch((error) => {
        console.error('Ошибка при копировании текста: ', error)
      })
  }

  const handleOpen = () => {
    alert('С каждой покупки реферала вы получите 5% на баланс')
  }

  return (
    <div className="profile" style={{ height: '100vh', overflow: 'auto' }}>
      <div
        style={{
          backgroundColor,
          borderRadius: '20px',
        }}
      >
        <div
          className="profile-container"
          style={{ backgroundColor, width: '100%' }}
        >
          {avatarUrl ? (
            <img className="profile-image" src={avatarUrl} alt="Профиль" />
          ) : (
            <div
              className="profile-avatar-placeholder profile-image rounded-circle mr-3 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: 'gray',
                color: 'var(--tg-theme-bg-color)',
              }}
            >
              {user?.first_name.charAt(0).toUpperCase()}
            </div>
          )}
          <div
            className="profile-info"
            style={{
              backgroundColor,
              color: 'var(--tg-theme-text-color)',
              flexGrow: 1,
            }}
          >
            <div style={{ backgroundColor }}>
              <div
                className="profile-name"
                style={{ backgroundColor, color: 'var(--tg-theme-text-color)' }}
              >
                {user?.first_name}&nbsp;{user?.last_name}
              </div>
            </div>
            <div className="profile-username" style={{ backgroundColor }}>
              @{user.username}
            </div>
          </div>
        </div>
      </div>

      {role === 'social-media' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start', // изменено с 'center' на 'flex-start'
            backgroundColor: 'var(--tg-theme-secondary-bg-color)',
            padding: '10px 15px',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          <div
            className="gradient-text"
            style={{ backgroundColor: 'transparent', fontWeight: 'bolder' }}
          >
            Партнерская программа PayChara
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'transparent',
              lineHeight: '1.2',
              marginTop: '2px',
            }}
          >
            <div
              style={{
                fontSize: '0.8em',
                margin: '0',
                padding: '0',
                backgroundColor: 'transparent',
              }}
            >
              Доход:
            </div>
            <div
              style={{
                fontSize: '18px',
                margin: '0',
                padding: '0',
                backgroundColor: 'transparent',
                fontWeight: 'bolder',
              }}
            >
              {totalSalary} ₽
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
          padding: '10px 15px',
          borderRadius: '10px',
          marginTop: '20px',
        }}
      >
        <div
          className="balance"
          style={{
            backgroundColor: 'transparent',
            color: 'var(--tg-theme-text-color)',
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '1.2',
            width: '30%',
          }}
        >
          <div
            style={{
              fontSize: '0.8em',
              margin: '0',
              padding: '0',
              backgroundColor: 'transparent',
            }}
          >
            Баланс:
          </div>
          <div
            style={{
              fontSize: '18px',
              margin: '0',
              padding: '0',
              backgroundColor: 'transparent',
              fontWeight: 'bolder',
            }}
          >
            {balance} ₽
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ backgroundColor, flexGrow: '1', width: '50%' }}
        >
          <Link
            to="/replenish"
            className="profile-balance profile-gradient-button w-100"
            style={{
              textDecoration: 'none',
              backgroundColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }} // Добавьте это
          >
            <button className="profile-balance profile-gradient-button">
              Пополнение
            </button>
          </Link>
        </div>
      </div>

      <div
        style={{
          backgroundColor,
          marginTop: '20px',
          padding: '15px',
          borderRadius: '20px',
          backgroundColor:
            tg.colorScheme === 'light'
              ? 'var(--tg-theme-secondary-bg-color)'
              : '#1D1D1D',
          // opacity: '0.8',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor,
            color: 'white',
            alignItems: 'center',
            color: 'var(--tg-theme-text-color)',
            fontWeight: 'bolder',
          }}
        >
          Реферальная ссылка
          <InfoIcon onClick={handleOpen} />
        </div>

        <div
          className="d-flex align-items-center ref "
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            marginTop: '10px',
            height: '4rem',
          }}
        >
          <div
            className="ref-icons"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0)',
            }}
          >
            <LinkIcon
              fill="var(--tg-theme-text-color)"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
            />
          </div>
          <div
            className="ref-link d-flex align-items-center justify-content-between"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0)',
              textAlign: 'center',
              flex: 1,
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0)',
                textAlign: 'center',
                justifyContent: 'center',
                color: 'var(--tg-theme-text-color)',
              }}
            >
              {referralLink}
              <br />
            </div>
          </div>

          <div
            className="ref-icons"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
          >
            <button
              onClick={handleButtonClick}
              disabled={buttonDisabled}
              // style={{ backgroundColor: 'var(--tg-theme-secondary-bg-color)' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
            >
              {buttonDisabled ? (
                <CheckIcon
                  // fill="var(--tg-theme-text-color)"
                  style={{
                    // backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  }}
                />
              ) : (
                <CopyIcon
                  fill="var(--tg-theme-text-color)"
                  style={{
                    // backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  }}
                />
              )}
            </button>
          </div>
        </div>

        <div
          style={{
            backgroundColor,
            color: 'white',
            alignItems: 'center',
            color: 'var(--tg-theme-text-color)',
            fontWeight: 'bolder',
          }}
        >
          Промокод
        </div>

        <div
          className="d-flex align-items-center ref "
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            marginTop: '10px',
            height: '4rem',
          }}
        >
          <div
            className="ref-link d-flex align-items-center justify-content-between"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0)',
              textAlign: 'center',
              flex: 1,
            }}
          >
            <div
              className="d-flex"
              style={{ backgroundColor: 'transparent', alignItems: 'center' }}
            >
              <div
                className="ref-icons"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  padding: '0 10px',
                }}
              >
                <TicketIcon2
                  fill="var(--tg-theme-text-color)"
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  }}
                />
              </div>
              <input
                className="mr-2 flex-grow-1 input-group__promo"
                style={{
                  backgroundColor: 'transparent',
                  alignItems: 'center',
                  color: 'white',
                  '::placeholder': {
                    // Цвет placeholder
                    color: 'lightpurple',
                  },
                }}
                type="text"
                id="myInput"
                value={code}
                onChange={handleCodeChange}
                placeholder="Промокод"
              />
              <button
                onClick={handleApplyClick}
                disabled={isSubmitting}
                style={{
                  padding: '0',
                  width: '62px',
                  height: '32px',
                  color: 'green',
                  fontSize: '1.2em',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                  marginLeft: '10px',
                  marginRight: '5px',
                  alignItems: 'center',
                }}
              >
                <SendPromoIcon style={{ backgroundColor: 'transparent' }} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={handleAdminClick}
        style={{
          width: 'calc(100% - 10px)',
          margin: '10px 5px',
          padding: '0px',
          borderRadius: '20px',
          alignItems: 'center',
          border: 'none',
          display: role === 'admin' || role === 'manager' ? 'block' : 'none',
        }}
        className="admin-button"
      >
        Админ-панель
      </button>
    </div>
  )
}

export default ProfilePage
